import React                   from 'react';
import { graphql }             from 'gatsby'
import { Col, Container, Row } from 'react-grid-system';

import Carousel from '../../components/Carousel';
import Wrapper  from '../../components/Wrapper';
import Heading  from '../../components/Heading';
import Spacer   from '../../components/Spacer';

import * as S        from './styles';
import DefaultLayout from '../../layouts/Default';

function LegalPageTemplate({ data, location }) {
  const html = data.legal.html;
  const meta = data.legal.frontmatter;
  const contact = data.contact.edges[0].node.contact;

  return (
    <DefaultLayout location={location}>
      <Carousel>
        <img src={meta.headerImage} alt={''}/>
      </Carousel>
      <Wrapper>
        <Spacer/>
        <Heading>{meta.title}</Heading>
        <Spacer/>
        <Container fluid>
          <Row>
            <Col md={4}>
              <S.Aside>
                <h3>Impressum</h3>
                <section>
                  <h4>Angaben gemäß §5 TMG:</h4>
                  <p>{contact.name}</p>
                  <Spacer height={10}/>
                  <p>Vertreten durch {contact.legal.executive}</p>
                  <Spacer height={10}/>
                  <p>{contact.address.street}</p>
                  <p>{contact.address.place}</p>
                </section>
                <section>
                  <h4>Kontakt</h4>
                  <p>Tel: {contact.contact.phone}</p>
                  <p>Fax: {contact.contact.fax}</p>
                  <p>Email: {contact.contact.email}</p>
                </section>
                <section>
                  <h4>Handelsregister</h4>
                  <p>Registereintrag:</p>
                  <p>Eintragung im Handelsregister: {contact.legal.register.number}</p>
                  <p>Amtsgericht: {contact.legal.register.registrar}</p>
                </section>
                <section>
                  <h4>Umsatzsteuer</h4>
                  <p>
                    Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: {contact.legal.taxNumber}
                  </p>
                </section>
              </S.Aside>
            </Col>
            <Col md={8}>
              <S.J dangerouslySetInnerHTML={{ __html: html }}/>
            </Col>
          </Row>
        </Container>
        <Spacer/>
      </Wrapper>
    </DefaultLayout>
  )
}

export default LegalPageTemplate;

export const query = graphql`
  query LegalQuery($slug: String!) {
    legal: markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      frontmatter {
        title
        slug
        headerImage
      }
      html
    }
    contact: allConfigJson {
      edges {
        node {
          ...Contact
        }
      }
    }
  }
`;
